import types from './questionTypes';

const initialState = {
  activeQuestion: null,
  questionList: [],
  isShowLoader: false,
  activePercent: 0,
  answerList: [],
  questionListLength: 0,
};

const questionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_QUESTION_LIST: {
      return { ...state, ...payload };
    }
    case types.SET_ACTIVE_QUESTION: {
      return { ...state, activeQuestion: payload };
    }
    case types.FINISH_LOADER_QUESTION: {
      return { ...state, isShowLoader: true };
    }
    case types.START_LOADER_QUESTION: {
      return { ...state, isShowLoader: false };
    }
    case types.SET_PERCENT_QUESTION: {
      return { ...state, activePercent: payload };
    }
    case types.SET_ANSWER: {
      return { ...state, answerList: payload };
    }
    default:
      return state;
  }
};

export default questionReducer;
