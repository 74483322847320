import { get, put, post } from '../apiService';

export const getWorkouts = queryParams => {
    return get(`/workout/${queryParams}`);
};

export const getWorkoutById = id => {
    return get(`/workout/${id}`);
};
export const getPopularWorkouts = () => {
    return get(`/plan/popular`);
};
export const getFavouriteWorkouts = (data) => {
    return post(`/plan/favourite`, data);
};
export const sendWorkoutPoints = pt => {
    return post('/workout/point', { point: pt });
};
export const getTestWorkout = () => {
    return get(`/workout/test`);
};

export const getSavedWorkouts = () => {
    return get('/user/saved-workouts');
};

export const addWorkoutToSaved = id => {
    return put('/user/saved-workouts', { id, action: 'ADD' });
};

export const removeWorkoutFromSaved = id => {
    return put('/user/saved-workouts', { id, action: 'REMOVE' });
};

export const rateWorkout = (userRate, like, reason, day, workoutId) => {
    return post('/progress/save-user-rate', { userRate, like, reason, day, workoutId });
};

export const changeDifficulty = action => {
    return post('/progress/change-difficulty', { action });
};
