import React from 'react';

import './styles.scss';

const Button = ({
                    onClick = () => {
                    },
                    disabled = false,
                    loadingStatus,
                    options = {type: 'button'},
                    className = '',
                    children,
                    id = '',
                }) => {
    const {
        renderType = 'primary',
        size = 'l',
        text = '',
        type,
    } = options;
    return (
        <button key={id}
                className={`custom-button custom-button-${renderType} custom-button-${size} ${className}`}
                disabled={disabled || loadingStatus}
                onClick={onClick}
                type={type}
        >
            {loadingStatus ? (
                <div className="spinner"/>
            ) : (
                <>{text ? text : children}</>
            )}
        </button>
    );
};

export default Button;
