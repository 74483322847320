import {authTypes} from "./authenticationTypes";

const initialState = {
    data: null,
    isLoading: false,
    error: null,
    id: null
}

export const authReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case authTypes.SAVE_PROFILE_DATA:
            return {...state, data: payload}
        case authTypes.REMOVE_PROFILE_DATA:
            return {...state, data: null}
        case authTypes.UPDATE_PROFILE_DATA:
            return {...state, data: {...state.data, ...payload}}
        case authTypes.SET_PROFILE_LOADING_STATUS:
            return {...state, isLoading: payload}
        case authTypes.FORGOT_PASSWORD_CODE_SUCCESS:
            return {...state, ...payload}
        case authTypes.FORGOT_PASSWORD_SUCCESS:
            return {...state, ...payload}
        default:
            return state
    }
}
