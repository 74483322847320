import { setNotifyStatus } from './notifyActions';

export const errorHandler = error => dispatch => {
  dispatch(
    setNotifyStatus({
      type: 'error',
      title: getError(error),
    }),
  );
};

export const successHandler = message => dispatch => {
    dispatch(
        setNotifyStatus({
            type: 'success',
            title: message
        })
    )
}
const getError = message => (message ? message : `An error has occurred`);
