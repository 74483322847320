import types from './onboardingTypes';

const initialState = {
  activeStep: 0,
  onboardingList: [],
  typeOnboarding: 'ONBOARDING',
  statusOnboarding: false,
  isShowed: false,
};

const onboardingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.OPEN_ONBOARDING: {
      return { ...state, typeOnboarding: payload, statusOnboarding: true };
    }
    case types.SET_ONBOARDING_LIST: {
      return { ...state, onboardingList: payload };
    }
    case types.SET_STEP_ONBOARDING: {
      return { ...state, activeStep: payload };
    }
    case types.CHECK_ONBOARDING: {
      return { ...state, isShowed: payload };
    }
    case types.CLOSE_ONBOARDING: {
      return {
        ...state,
        typeOnboarding: payload,
        statusOnboarding: false,
      };
    }
    default:
      return state;
  }
};

export default onboardingReducer;
