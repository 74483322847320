import React from 'react';
import Select from 'react-select';
import './styles.scss';

const CustomSelect = ({ onChange = () => {}, value = [], defaultValue }) => {
  const _paymentMethodOptionTemplateItem = ({ label }) => (
    <div className="customs-select__container">
      <p className="customs-select__container_item">{label}</p>
    </div>
  );

  return (
    <div className="select">
      <Select
        onChange={onChange}
        defaultValue={defaultValue ? defaultValue : value[0]}
        formatOptionLabel={_paymentMethodOptionTemplateItem}
        options={value}
        className="customs-select"
        classNamePrefix="customs-select"
      />
    </div>
  );
};
export default CustomSelect;
