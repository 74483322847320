import { withTranslation } from 'react-i18next';
import { Button } from '../../../ui-core';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { openModal } from '../../redux/modalOperations';
import modalType from '../../types';
import './styles.scss';
import imgClose from '../../../../assets/images/myPlan/imgClose.png'
const TimeVideoModal = ({ onClose, data, t }) => {
  const {
    title,
    description,
    descriptionSub,
    button_ok_text,
    button_cancel_text,
  }
    = data;
  const value = [{ value: 1, time: 60 }, { value: 2, time: 120 }, { value: 3, time: 180 }, {
    value: 4,
    time: 220,
  }, { value: 5, time: 280 }];
  const [activeTime, setActiveTime] = useState(60);
  const dispatch = useDispatch();
  const getButtonTime = () => (value.map(({ value, time },index) => (
    <div className={`time-video__time-item ${activeTime === time ? 'time-video__time-item-active' : ''}`}
         key={'getButtonTime'+index}
         onClick={() => {
           setActiveTime(time);
         }}> {value}</div>
  )));
  const _openVideo = () => {
    dispatch(
      openModal({
        type: modalType.VIDEO_MODAL,
        data: {
          callback_button_cancel: () => {
          },
        },
      }),
    );
  };
  return (
    <div className='time-video'>
      <img onClick={onClose} src={imgClose} className='time-video-close' alt={'close'}/>
      <p className='time-video-title'>{t(title)}</p>
      <p className='time-video-description'>{t(description)}
        <span> {t(descriptionSub)}</span>
      </p>
      <div className='time-video__time'>{getButtonTime()}</div>
      <div className='time-video__button'>
        <Button className='time-video__button-cancel' onClick={_openVideo}>{t(button_cancel_text)}</Button>
        <Button className='time-video__button-ok' onClick={_openVideo}>{t(button_ok_text)}</Button>
      </div>
    </div>
  );
};

export default withTranslation()(TimeVideoModal);