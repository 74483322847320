import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../ui-core';
import OnboardingHints from './components/OnboardingHints/onboardingHints';
import { nextStep } from './redux/onboardingOperations';
import './styles.scss';

const Onboarding = ({ t }) => {
  const { statusOnboarding } = useSelector(store => store.onboardingReducer);

  const dispatch = useDispatch();

  const goNext = () => {
    dispatch(nextStep());
  };
  const getContent = () => {
    if (statusOnboarding) {
      return <OnboardingHints />;
    }
  };
  return (
    <div className={statusOnboarding ? 'onboarding' : 'onboarding-close'}>
      {getContent()}
        <Button
          className="onboarding__button onboarding__button-next"
          onClick={goNext}
        >
          {t('next_step')}
        </Button>
    </div>
  );
};

export default withTranslation()(Onboarding);
