import routerBook from '../../../../routes/routerBook';
import logo from '../../../../assets/images/vectors/white-logo.svg';
import dayjs from 'dayjs';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { tertiaryConfig2 } from '../tertiaryConfig';
import arrowDown from '../../../../assets/images/header/arrowDown.svg';
import arrowDownMobile from '../../../../assets/images/header/arrowDownMobile.svg';

import user from '../../../../assets/images/header/user.svg';
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../../../pages/authentication/redux/authenticationOperations';
import { Logo } from '../../logo/Logo';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { updateData } from '../../../../services/api/profileService/profileService';
import { errorHandler } from '../../../toastify/redux/notifyOperations';

const TertiaryHeader = ({ t }) => {
    const { pathname } = useLocation()
    const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data } = useSelector(store => store.authState)
    useEffect(() => {
        setIsOpenMobileMenu(false)
    }, [pathname])

    useEffect(() => {
        const body = document.querySelector('body');
        body.style.overflow = isOpenMobileMenu ? 'hidden' : 'auto';
    }, [isOpenMobileMenu]);

    const logoClickHandler = () => {
        navigate(routerBook.dashboard);
        closeMobileMenu();
    };
    const logoutClickHandler = () => {
        dispatch(logoutUser());
    }


    const closeMobileMenu = () => {
        setIsOpenMobileMenu(false);
    };

    const onTryBetaSite = () => {
        updateData({
            email: data.email,
            name: data.name,
            gender: data.gender,
            height: data.height,
            weight: data.weight,
            version: 2
        }).then(response => {
            document.location.href = '/dashboard'
        }).catch(error => {
            dispatch(errorHandler(error.message));
        });
    }

    return (
        <header className="header3-tertiary">
            <center>
                <div className='header3-tertiary-container'>
                    <div className="header3-tertiary__left">

                        <div className="header3-tertiary__left_container-icon">
                            <Logo onClick={logoClickHandler} className='header3-tertiary__left-logo' />
                        </div>


                    </div>
                    <div className={`header3-tertiary__right-container`}>

                        <div className='header3-tertiary__right'>
                            <Popup
                                trigger={<div className="menu-item-2"> <span className="header3-tertiary__right-user-title">{data?.name}</span>
                                    <img className="header3-tertiary__right-user-arrow" src={arrowDown} alt="arrow" /></div>}
                                position="bottom right"
                                on="click"
                                closeOnDocumentClick
                                mouseLeaveDelay={300}
                                mouseEnterDelay={0}
                                contentStyle={{ padding: '0px', border: 'none' }}
                                arrow={false}
                            >
                                <div className="menu">
                                    {/* <div className="menu-item" onClick={() => { document.location.href = '/dashboard/profile/account' }}> Account</div> */}
                                    <div className="menu-item" onClick={() => { document.location.href = '/dashboard/profile/about-me' }}> About Me</div>
                                    <div className="menu-item" onClick={() => { document.location.href = '/dashboard/profile/contact-us' }}> Contact Us</div>
                                    <div className="menu-item" onClick={onTryBetaSite}> Try Beta Site</div>
                                    <div className="menu-item colored" onClick={logoutClickHandler}> Log out</div>
                                </div>
                            </Popup>
                        </div>
                    </div>
                </div>
            </center>
            <div className='header3-button-group'>
                <a href="/dashboard/workouts" > Workouts </a>
                <a href="/dashboard"> Workout programs</a>
                {/* <a href="/dashboard/profile"> Account</a> */}


            </div>
        </header>
    );
};
export default withTranslation()(TertiaryHeader);
