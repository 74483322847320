import { put, get, post } from "../apiService";

export const updateAvatar = (formData) => {
    return put(`/user/me`, formData)
}

export const updateData = (data) => {
    return put('/user/me', data)
}

export const getHintsStatus = () => {
    return get('/user/hints')
}

export const putHintsStatus = (data) => {
    return put('/user/hints', data)
}

export const sendFeedback = (data) => {
    return post('/feedback', data)
}

export const sendAdsViewed = () => {
    return post('/user/showAds', {})
}