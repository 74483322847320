export const getLocalRefreshToken = () => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    return auth?.refreshToken
};

export const getLocalAccessToken = () => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    return auth?.accessToken
};

export const updateLocalAccessToken = (token) => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    auth.accessToken = token;
    localStorage.setItem("auth", JSON.stringify(auth));
};

export const updateLocalRefreshToken = token => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    auth.refreshToken = token;
    localStorage.setItem("auth", JSON.stringify(auth));
}

export const rememberLocalAuthTokens = tokens => {
    localStorage.setItem("auth", JSON.stringify(tokens))
}

export const removeLocalAuthTokens = () => {
    localStorage.removeItem("auth");
};
