import types from './onboardingTypes';

export const setOnboardingType = data => ({
  type: types.OPEN_ONBOARDING,
  payload: data,
});
export const setOnboardingList = data => ({
  type: types.SET_ONBOARDING_LIST,
  payload: data,
});
export const setStepOnboarding = data => ({
  type: types.SET_STEP_ONBOARDING,
  payload: data,
});
export const closeOnboarding = () => ({
  type: types.CLOSE_ONBOARDING,
});
export const checkOnboarding = data => ({
  type: types.CHECK_ONBOARDING,
  payload: data,
});
