import axios from 'axios'

export class StripeService {
    constructor() {
        this.service = axios.create({
            baseURL: 'https://api.stripe.com/v1/'
        })
    }

    #createParams = (data) => {
        let formattedData = {}
        Object.keys(data).forEach(key => Object.assign(formattedData, {[`card[${key}]`]: data[key]}))
        return formattedData
    }

    createPaymentMethod = async (token, data) => {
        return await this.service.post('payment_methods', null, {
            params: {
                type: 'card',
                ...this.#createParams(data)
            },
            headers: {
                authority: 'api.stripe.com',
                accept: 'application/json',
                Authorization: 'Bearer ' + token,
                'content-type': 'application/x-www-form-urlencoded',
                origin: 'https://js.stripe.com'
            }
        })
    }
}